<template>
  <div>
    <label
      v-if="label"
      :for="id"
    >
      {{label}}
      <em
        v-if="required"
        class="required-mark">*</em>
    </label>
    <div
      class="input-container"
      :class="inputContainerClasses"
    >
      <textarea
        :id="id"
        class="input-border"
        v-bind="$attrs"
        :value="value"
        @input="onInput"
      ></textarea>
      <span class="focus-border">
        <i></i>
      </span>
    </div>
  </div>
</template>

<script>
/**
 * 기본 Textarea 컴포넌트
 */
export default {
  name: 'BaseTextarea',

  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,

  props: {
    /**
     * 입력값
     * @model
     */
    value: {
      type: String,
    },

    /**
     * 레이블
     */
    label: {
      type: String,
    },

    /**
     * 사이즈
     * @values basic, large
     */
    size: {
      type: String,
      default: 'basic',
      validator: (value) => [
        'basic',
        'large',
      ].indexOf(value) !== -1,
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `textarea-${Math.random().toString(36).slice(2)}`;
      },
    },

    /**
     * 필수 여부
     */
    required: {
      type: Boolean,
      default: false,
    },

    /**
     * 유효성 검사 실패 여부
     */
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputContainerClasses() {
      return {
        memo: this.size === 'large',
        error: this.invalid,
      };
    },
  },

  methods: {
    onInput(event) {
      /**
       * 입력 이벤트
       * @event input
       * @property {string} 변경된 입력값
       */
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style scoped>
  .input-container textarea{ resize: none; display: block;}
  .input-container textarea:focus { outline: 0;}
  .input-container .input-border ~ .focus-border:before,
  .input-container .input-border ~ .focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #000;
    transition: 0.2s;
  }

  .input-container .input-border ~ .focus-border:after {
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
  }

  .input-container .input-border ~ .focus-border i:before,
  .input-container .input-border ~ .focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 0;
    background-color: #000;
    transition: 0.2s;
  }

  .input-container .input-border ~ .focus-border i:after {
    right: auto;
    left: 0;
    top: auto;
    bottom: 0;
  }

  .input-container .input-border:focus ~ .focus-border:before,
  .input-container .input-border:focus ~ .focus-border:after {
    width: 100%;
  }

  .input-container .input-border:focus ~ .focus-border:after {
    transition-delay: 0.2s;
  }

  .input-container .input-border:focus ~ .focus-border i:before,
  .input-container .input-border:focus ~ .focus-border i:after {
    height: 100%;
  }

  .input-container .input-border:focus ~ .focus-border i:before {
    transition-delay: 0.1s;
  }

  .input-container .input-border:focus ~ .focus-border i:after {
    transition-delay: 0.3s;
  }
</style>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BaseTextarea value="가나다ABC" placeholder="입력해주세요..." />
```

레이블(label), 필수 여부(required), 유효성 검사 실패 여부(invalid) 사용 예시 :
```jsx
<BaseTextarea
  id="content12"
  label="주간보고"
  value=""
  required
  invalid
/>
<span class="input-desc">실시사항과 예정사항을 구분해서 입력해 주세요.</span>
<span class="input-error-desc">주간보고 내용을 입력해 주세요.</span>
```

사이즈 사용 예시 :
```jsx
<BaseTextarea label="내용" placeholder="남기고 싶은 메세지를 입력해 주세요." size="large" />
```
</docs>
