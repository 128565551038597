<template>
  <div class="tab-contents-wrap">
    <div class="title-wrap">
      <h4>{{eventInfo.name}}</h4>
      <div class="header-info">
        <ul>
          <li class="date">
            {{ getDateString(eventInfo.startDate, eventInfo.endDate) }}
          </li>
          <li class="date v-bar">
            {{eventInfo.placeName || eventInfo.placeDetail}}
          </li>
        </ul>
      </div>
    </div>

    <template v-if="statusType === 1">
    <div class="form-container">
      <ValidationObserver ref="form">
      <form>
        <fieldset>
          <legend>{{$t('content.event.Event.txt33')}}</legend>
          <ul>
            <li class="form-row">
              <ValidationProvider
                :rules="{ required: true }"
                :name="$t('content.event.Event.txt49')"
                v-slot="{ errors }"
              >
                <BaseInput
                  v-model="member_name"
                  :label="$t('content.event.Event.txt49')"
                  :class="{'error':errors[0]}"
                  :placeholder="$t('content.event.Event.txt50')"
                  required="">
                </BaseInput>
                <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
              </ValidationProvider>
            </li>
            <li class="form-row">
              <ValidationProvider
                :rules="{ required: true, email: true }"
                :name="$t('content.event.Event.txt51')"
                v-slot="{ errors }"
              >
                <BaseInput
                  v-model="member_email"
                  :class="{'error':errors[0]}"
                  :label="$t('content.event.Event.txt51')"
                  :placeholder="$t('content.event.Event.txt52')"
                  required="">
                </BaseInput>
                <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
              </ValidationProvider>
            </li>
            <li class="form-row">
              <ValidationProvider
                :rules="{ required: true, phone:true }"
                :name="$t('content.event.Event.txt53')"
                v-slot="{ errors }"
              >
                <TelInput
                  v-model="phone"
                  :label="$t('content.event.Event.txt53')"
                  :placeholder="$t('content.event.Event.txt54')"
                  :class="{'error':errors[0]}"
                  required
                  maxlength="13"
                />
                <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
              </ValidationProvider>
            </li>
            <li class="form-row">
              <BaseTextarea
                v-model="memo"
                :label="$t('content.event.Event.txt55')"
                size="large"
                :placeholder="$t('content.event.Event.txt56')"
              />
            </li>
          </ul>
        </fieldset>
      </form>
      </ValidationObserver>
      <div class="terms-area">
        <div class="terms-container">
          <ol>
            <li>{{$t('content.event.Event.txt57')}}</li>
            <li>{{$t('content.event.Event.txt58')}}</li>
            <li>{{$t('content.event.Event.txt59')}}
            </li>
          </ol>
        </div>
        <div class="checkbox-container">
          <BaseCheckbox
            v-model="agreed"
            required
          >{{$t('content.event.Event.txt60')}}
          </BaseCheckbox>
        </div>
      </div>
    </div>
    <!-- 버튼 -->
    <div class="btn-wrap">
      <a href="#" @click="goBack" class="back">Back</a>
      <button
        @click="apply"
        :disabled="!agreed"
        class="btn-basic"
      >{{$t('content.event.Event.txt61')}}
      </button>
    </div>
    <!-- // 버튼 -->
    </template>

    <template v-if="statusType === 2">
      <!-- 이벤트 신청 성공결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result" data-aos="flip-right">
              <dl class="completed">
                  <dt>{{$t('content.event.Event.txt62')}}</dt>
                  <dd class="desc">
                  </dd>
              </dl>
          </div>
      </div>
      <div class="btn-wrap perspective">
        <button class="btn-basic" @click="$router.push({name:'EventDetail', params: {eventNo:eventNo}})">
          {{$t('content.event.Event.txt63')}}
        </button>
      </div>
      <!-- // 이벤트 신청 성공결과 -->
    </template>
    <template v-if="statusType === 3">
      <!-- 이벤트 신청 에러결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result" data-aos="flip-right">
              <dl class="failed">
                  <dt>{{$t('content.event.Event.txt64')}}</dt>
                  <dd class="desc" v-html="errMsg">
                  </dd>
              </dl>
          </div>
      </div>
      <!-- // 이벤트 신청 에러결과 -->
      <div class="btn-wrap perspective">
        <button class="btn-basic" @click="$router.push({name:'EventDetail', params: {eventNo:eventNo}})">
          {{$t('content.event.Event.txt63')}}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion, format } from '@/common/helperUtils';
import BaseInput from '@/components/base/BaseInput.vue';
import TelInput from '@/components/base/TelInput.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  async beforeRouteLeave(to, from, next) {
    if (this.statusType === 1) {
      this.showConfirm(this.$t('content.event.Event.alertMsg.msg4'), () => {
        next();
      });
    } else {
      next();
    }
  },
  name: 'ApplyNonMember',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    TelInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    /**
     * EventNo: 이벤트 항목번호
     */
    eventNo: {
      type: [Number, String],
      required: true,
    },
    /**
     * EventInfo: 이벤트 항목의 상세정보
     */
    eventInfo: {
      type: Object,
      required: true,
    },
    /**
     * EventDate: 이벤트 항목의 달
     */
    eventDate: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      statusType: 1,
      format,
      member_name: '',
      member_email: '',
      phone: '',
      memo: '',
      agreed: false,
      errMsg: '',
    };
  },
  created() {
    console.log('applynonmember');
    window.hwisam = this;
  },
  methods: {
    apply() {
      this.validateForm(this.$refs.form).then(() => {
        // const phoneNumber = this.phone.split('-');
        this.portalApiClient({
          url: APIs.EVENT_APPLY_NONE,
          data: {
            eventId: this.eventInfo.id,
            name: this.member_name,
            email: this.member_email,
            phone: this.phone,
            memo: this.memo,
            private_info_terms_tag: true,
            isChangeMemberPhone: false,
          },
        }).then(({ data }) => {
          if (data.result) {
            this.statusType = 2;
          } else {
            this.statusType = 3;
            this.errMsg = data.message;
          }
        }).catch(() => {
          this.statusType = 3;
          this.errMsg = `일시적인 기술적 오류로 이벤트신청이 완료되지 못했습니다.<br>
              다시 한 번 신청을 진행해주세요.`;
        });
      });
    },
    goBack(event) {
      event.preventDefault();
      this.showConfirm(this.$t('content.event.Event.alertMsg.msg4'), () => {
        this.$router.go(-1);
      });
    },
    getDateString(startDate, endDate) {
      // 이벤트 기간 타이틀 문자열
      if (format.date(startDate) == format.date(endDate)) {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.time(this.$moment(endDate).format('HHmm'))}`;
      } else {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.date(endDate)} ${format.time(this.$moment(endDate).format('HHmm'))}`;
      }
    }
  },
};
</script>
